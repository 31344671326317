import React, { FC, useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { graphql } from 'gatsby';

import useScreenRecognition from 'hooks/useScreenRecognition';
import Video from 'common/Video';
import { UmbracoVideoListSection } from '@shared/types/umbraco';
import Section from 'common/Section';
import Button from 'common/Button';
import './VideoListSection.scss';

const VideoListSection: FC<UmbracoVideoListSection.IData> = ({
  color,
  videos = [],
  intro,
  limit = 0,
  limitMobile = 0,
  ctaLabel,
  ariaLabel,
}) => {
  const ref = useRef(null);
  const total = videos.length;
  const { isNotMobile } = useScreenRecognition();
  const [prevShowCount, setPrevShowCount] = useState(0);
  const [toShow, setToShow] = useState<number>(limitMobile);
  const stepLoad = useMemo(
    () => (isNotMobile ? Math.min(limit, total) : Math.min(limitMobile, total)),
    [limit, limitMobile, total, isNotMobile]
  );

  useEffect(() => {
    setPrevShowCount(toShow);
  }, [toShow]);

  const loadMore = useCallback(() => {
    const count = Math.min(total, toShow + stepLoad);
    setToShow(count);
  }, [total, toShow, stepLoad]);

  const handleLoadMore = () => {
    loadMore();
    setTimeout(() => {
      ref.current?.children[prevShowCount]
        ?.getElementsByClassName('video__play-icon')?.[0]
        ?.focus();
    }, 0);
  };

  useEffect(() => {
    const count = Math.min(total, stepLoad);
    setToShow(count);
  }, [total]);

  return (
    <Section name="video-list" intro={intro} color={color}>
      {videos.length > 0 && (
        <>
          <ul ref={ref} className="video-list-section__grid">
            {videos?.slice(0, toShow).map(({ properties }) => (
              <li key={properties.youtubeLink}>
                <Video {...properties} />
              </li>
            ))}
          </ul>
          {videos.length > 0 && ctaLabel && toShow < videos.length ? (
            <div className="video-list-section__more">
              <Button
                ariaLabel={ariaLabel}
                clickHandler={handleLoadMore}
                classes="video-list-section__btn"
                variant="secondary"
              >
                {ctaLabel}
              </Button>
            </div>
          ) : null}
        </>
      )}
    </Section>
  );
};

export const query = graphql`
  fragment FragmentVideoListSection on TVideoListSection {
    properties {
      intro
      videos {
        properties {
          youtubeLink
          vimeoLink
          btnAriaLabel
          time
          description
          previewImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
      limit
      limitMobile
      color {
        label
      }
      ctaLabel
      ariaLabel
    }
    structure
  }
`;

export default VideoListSection;
