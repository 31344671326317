import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import Section from 'common/Section';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { UmbracoIframeBlock } from '@shared/types/umbraco';
import './IframeBlock.scss';

const IframeBlock: FC<UmbracoIframeBlock.IData> = ({
  intro,
  color,
  iframeLink,
  script,
  caption,
  altLabel,
  id,
  width,
  height,
}) => {
  useEffect(() => {
    if (script) {
      const scriptEl = document.createElement('script');

      scriptEl.src = script;

      scriptEl.async = true;

      document.body.appendChild(scriptEl);

      return () => {
        document.body.removeChild(scriptEl);
      };
    }
  }, []);

  return iframeLink ? (
    <Section name="iframe" intro={intro} color={color}>
      <figure className="iframe-block">
        <iframe
          loading="lazy"
          id={id}
          style={{ width: width ? `${width}px` : '100%', height: height ? `${height}px` : '100%' }}
          title={altLabel}
          src={iframeLink}
        />
        {caption ? (
          <DangerouslySetInnerHtml
            className="iframe-block__caption"
            element="figcaption"
            html={caption}
          />
        ) : null}
      </figure>
    </Section>
  ) : null;
};

export const query = graphql`
  fragment FragmentIframeBlock on TIframeBlock {
    properties {
      intro
      color {
        label
      }
      iframeLink
      script
      caption
      altLabel
      id
      width
      height
    }
    structure
  }
`;

export default IframeBlock;
