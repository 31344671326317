import React, { FC, useState, useRef, useEffect } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { graphql } from 'gatsby';
import axios from 'axios';
import { SignUpBlockTypes } from '@shared/types';

import { Container } from 'layout';
import { isBrowser } from 'utils/browser';
import { gtmService } from 'utils/gtmService';

import Form from './components/Form';
import ThankYou from './components/ThankYou';

import awsconfig from '../../aws-exports';

import './SignUpBlock.scss';

const SignUpBlockChildren: FC<SignUpBlockTypes.IData> = ({
  description,
  fieldDisclaimer,
  labelFirstName,
  labelLastName,
  labelEmail,
  disclaimer,
  labelCheckbox,
  submitButton,
  ariaButton,
  message,
  cta,
  thankImage,
  errorMessage,
  formConfig,
  personalizeAdvertising,
  collapsibleCheckboxText,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const ref = useRef(null);

  const [formState, setFormState] = useState({
    isSent: false,
    isSending: false,
    isError: false,
  });

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    policy: false,
  });

  const { isCheckboxRequired } = formConfig?.[0]?.properties || {};

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const googleCaptcha = await executeRecaptcha('newsletterSignUp');
    const routeConfig = awsconfig?.aws_cloud_logic_custom?.find((el) => el.name === 'signUpApi');

    if (routeConfig) {
      setFormState((oldState) => ({
        ...oldState,
        isSending: true,
      }));

      const url = `${routeConfig.endpoint}/sign-up`;

      const today = new Date();
      const {
        body: configBody = '',
        headers,
        revisionIds = '',
      } = formConfig?.[0]?.properties || {};

      const bodyToCreate = configBody
        .replace(/\$EmailAddress$/gi, formData.email)
        .replace(/\$name$/gi, `${formData.first_name} ${formData.last_name}`)
        .replace(/\$formTime$/gi, `${today}`)
        .replace(/\$regTime$/gi, today.toISOString())
        .replace(/\$Agreements$/gi, JSON.stringify(revisionIds));

      const body = {
        headers,
        body: {
          ...JSON.parse(bodyToCreate),
          accepts_marketing: true,
          Accept_targeting: formData.policy,
          Accept_profiling: formData.policy,
        },
        googleCaptcha,
      };

      axios
        .post(url, body)
        .then(() => {
          setFormState((oldState) => ({
            ...oldState,
            isSent: true,
          }));
          gtmService.emitGenerateLead(gtmService.formNames.newsletter);
          ref.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        })
        .catch((error) => {
          console.error(error?.response?.data);
          setFormState({
            isSent: false,
            isSending: false,
            isError: true,
          });
        });
    }
  };

  return (
    <div ref={ref} className="sign-up section--bg-white" data-testid="sign-up">
      <Container fluid className={!formState.isSent ? 'sign-up--container' : ''}>
        {!formState.isSent ? (
          <Form
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            formState={formState}
            description={description}
            button={submitButton}
            ariaButton={ariaButton}
            labelCheckbox={labelCheckbox}
            labelEmail={labelEmail}
            labelFirstName={labelFirstName}
            labelLastName={labelLastName}
            fieldDisclaimer={fieldDisclaimer}
            disclaimer={disclaimer}
            errorMessage={errorMessage}
            setFormState={setFormState}
            collapsibleCheckboxText={collapsibleCheckboxText}
            personalizeAdvertising={personalizeAdvertising}
            isCheckboxRequired={isCheckboxRequired}
          />
        ) : null}
        {formState.isSent ? <ThankYou message={message} cta={cta} thankImage={thankImage} /> : null}
      </Container>
    </div>
  );
};

const SignUpBlock = (props) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const observer =
    isBrowser() &&
    new window.IntersectionObserver(([entry]) => {
      if (!show && entry.isIntersecting) {
        setShow(true);
      }
    });

  useEffect(() => {
    observer?.observe(ref.current);

    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <div ref={ref}>
      {show ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
          scriptProps={{ async: true, defer: false, appendTo: 'body' }}
        >
          <SignUpBlockChildren {...props} />
        </GoogleReCaptchaProvider>
      ) : (
        <SignUpBlockChildren {...props} />
      )}
    </div>
  );
};

export const query = graphql`
  fragment FragmentSignUpBlock on TSignUpBlock {
    properties {
      description
      fieldDisclaimer
      labelFirstName
      labelLastName
      labelEmail
      disclaimer
      labelCheckbox
      submitButton
      ariaButton
      message
      personalizeAdvertising
      collapsibleCheckboxText
      cta {
        properties {
          link {
            url
            target
            name
          }
          ariaLabel
        }
      }
      thankImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      errorMessage
      formConfig {
        properties {
          headers
          body
          revisionIds {
            BusinessId
            RevisionId
            ConsentDesc
            MandatoryInd
            ConsentAcceptedInd
            AgreementDate
            ActivityDate
          }
          isCDSMode
          isCheckboxRequired
        }
      }
    }
    structure
  }
`;

export default SignUpBlock;
