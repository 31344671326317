import React, { FC } from 'react';
import { IDescriptionGridLink } from '@shared/types';
import Button from 'common/Button';
import ImageCardGrid from 'components/ImageCardGrid';
import Section from 'common/Section';
import './DescriptionGridLink.scss';
import { graphql } from 'gatsby';

const DescriptionGridLink: FC<IDescriptionGridLink> = ({
  description,
  grid,
  link,
  gridCardSize,
  color,
}) => (
  <Section name="description-grid-link" intro={description} color={color}>
    <>
      {!!grid && <ImageCardGrid classes={gridCardSize?.[0]} {...{ grid }} />}
      {!!link?.length && (
        <div className="description-grid-link-section__more">
          <Button
            to={link?.[0].properties.link?.[0].url}
            variant="primary-link"
            classes="description-grid-link-section__button"
            iconSuffix="chevron-right"
            ariaLabel={link?.[0].properties.ariaLabel}
            target={link?.[0].properties.link?.[0].target}
          >
            {link?.[0].properties.link?.[0].name}
          </Button>
        </div>
      )}
    </>
  </Section>
);
export const query = graphql`
  fragment FragmentPartners on TPartners {
    properties {
      color {
        label
      }
      description
      gridCardSize
      grid {
        properties {
          description
          image {
            properties {
              imageAlt
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 150) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          link {
            name
            url
            target
          }
          isImageLink
        }
      }
      link {
        properties {
          ariaLabel
          link {
            name
            url
            target
          }
        }
      }
      showInMenu
      anchorName
      anchorTitle
    }
    structure
  }
`;

export default DescriptionGridLink;
