import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { UmbracoImageMapSection } from '@shared/types/umbraco';
import { DangerouslySetInnerHtml } from 'layout';
import ImageMap from 'common/ImageMap';
import Section from 'common/Section';
import './ImageMapSection.scss';

const ImageMapSection: FC<UmbracoImageMapSection.IData> = ({
  color,
  description,
  intro,
  imageAlignment,
  media,
}) => (
  <Section name="image-map" intro={intro} color={color} imageAlignment={imageAlignment}>
    <div className="image-map-section__grid">
      <div className="image-map-section__description">
        {description ? (
          <DangerouslySetInnerHtml
            className="image-map-section__description-text"
            html={description}
          />
        ) : null}
      </div>
      <div className="image-map-section__media">
        {media?.length ? <ImageMap {...media[0]?.properties} /> : null}
      </div>
    </div>
  </Section>
);

export const query = graphql`
  fragment FragmentImageMapSection on TImageMapSection {
    properties {
      intro
      description
      color {
        label
      }
      imageAlignment
      media {
        properties {
          name
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    original {
                      src
                      height
                      width
                    }
                  }
                }
              }
              imageAlt
            }
          }
          map {
            properties {
              shape
              alt
              tooltip
              coords
              xPosition
              yPosition
              activeState {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    original {
                      src
                      height
                      width
                    }
                  }
                }
              }
            }
          }
          caption
          fixedPosition
        }
      }
    }
    structure
  }
`;

export default ImageMapSection;
